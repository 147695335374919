const Footer = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginBottom: 10,
      }}
    >
      Made with ♥ by{" "}
      <a
        href="https://www.facebook.com/jp.jphabs"
        style={{ cursor: "pointer" }}
      >
        Jphabs Khalifa
      </a>
    </div>
  );
};

export default Footer;
